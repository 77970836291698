<template>
  <div class="page-container-table">
    <button-all-header
      :contentBtnAdd="'Thêm số thống kế'"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      class="custtom-header"
      @clickDelete="deleteItems"
      @search="search($event)"
      @clickAdd="$router.push({ name: 'accedent-statistical-add' }).catch(() => {})"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: số hiệu văn bản-->
        <span v-if="props.column.field === 'name'">
          {{ props.row.name }}
        </span>

        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'ActionFunction'">
          <span
            @click="showPDF(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xem báo cáo'"
              icon="EyeIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="showModalEdit(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Chỉnh sửa'"
              icon="EditIcon"
              size="18"
              class="text-body"
            />
          </span>
          <span
            class="ml-2"
            @click="deleteItem(props.row.id)"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Xóa'"
              icon="Trash2Icon"
              size="18"
              class="text-body"
            />
          </span>
        </span>
      </template>
    </vue-good-table>

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <ModalSeePDF
      :id="modalSeePdf"
      :pdfsrc="pdfSrc"
      @downloadFile="downloadFilePdf"
    />
  </div>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalSeePDF from '@/views/management-workers/worker-license/pages/components/ModalSeePdf.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    ConfirmModal,
    ModalSeePDF,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      totalRecord: 0,
      modalSeePdf: 'modal-see-pdf',
      showBtnMultiDelete: false,
      deleteIds: [],
      dataList: [],
      confirmModalId: 'confirm-modal',
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      modalContent: '',
      columns: [
        {
          label: 'TÊN CƠ QUAN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NĂM BÁO CÁO',
          field: 'year',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'ActionFunction',
          sortable: false,
          width: '150px',
        },
      ],
      pdfSrc: null,
    }
  },
  created() {
    this.getList()
  },

  methods: {

    showModalEdit(id) {
      this.$router.push({ name: 'accedent-statistical-edit', params: { id } })
    },

    async getList() {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST, {
        params: this.urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },
    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    showPDF(id, name) {
      const paload = {
        accidentStatisticalId: id,
        reportFormat: 'PDF',
      }
      axiosApiInstance({
        url: ConstantsApi.REPORT, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: paload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.pdfSrc = url
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file pdf
    downloadFilePdf() {
      axiosApiInstance({
        url: ConstantsApi.REPORT, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { workerLicenseId: this.workerLicenseAcceptId, type: this.typeStatus },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'SoThongKeTaiNanLaoDongCuaCoQuanChuyenNganh.pdf') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.getList(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.getList(this.urlQuery)
      }
    },

  },
}
</script>

<style>

</style>
